import {
    ColDef,
    ColGroupDef,
    ValueGetterParams,
    ValueFormatterParams,
    IRowDragItem,
} from 'ag-grid-community';
import {
    cellRenderers,
    gridComparators,
    gridFilterValueGetters,
    gridValueGetter,
    gridValueFormatter,
} from '../../../../components/SourceSetGrid/_utils';
import { ICustomData } from '../../../../components/SourceSetGrid/types';
import TranslationHelper from '../../../../helpers/TranslationHelper';
import { IAttribute, ISourceSet } from '../../../../state/types';
import { IUserSettings } from '../../../../state/user/types';
import { IActionHandlerParams } from '../../../../components/SourceSetGrid/_utils/types';
import { sparklineOptions } from './sparkline';

const filters = {
    number: 'agNumberColumnFilter',
    time: 'agDateColumnFilter',
    date: 'agDateColumnFilter',
};

export const getColumnDefs = (
    attributes: IAttribute[],
    customData: ICustomData | undefined,
    userSettings: IUserSettings,
    sourceSet: ISourceSet,
    enterprise?: boolean,
    multiSelectProps?: {
        checkBox?: { index: number };
        checkBoxHeader?: { index: number };
    },
    dragAndDropOptions?: {
        rowDrag?: (params: any) => boolean;
        rowDragText?: (params: IRowDragItem) => string;
    },
    hide?: { index: number },
    actionHandlers?: {
        [key: string]: (params: IActionHandlerParams) => void;
    },
    showAlways?: boolean,
    headerClass?: any
) => {
    const { checkBox, checkBoxHeader } = multiSelectProps || {};
    const { rowDrag, rowDragText } = dragAndDropOptions || {};
    return attributes.map(
        (
            {
                id,
                label,
                type,
                translate,
                formatting,
                children,
                selectable,
                rowGroup,
                cellClass,
                showAlways,
                columnGroupShow,
                collapsed,
                suppressColumnsToolPanel,
            },
            index
        ): ColDef | ColGroupDef => {
            if (children) {
                const getCheckbox = () => {
                    return selectable
                        ? { checkBox, checkBoxHeader }
                        : undefined;
                };

                const groupCollapsed = children.every(
                    (item) => item.showAlways === false
                );
                const mockAttribute: IAttribute[] = [
                    {
                        id: 'mockColumn',
                        label: '',
                        type: 'mock',
                        showAlways: true,
                        suppressColumnsToolPanel: true,
                        columnGroupShow: 'closed',
                    },
                ];
                const childrenAttributes = groupCollapsed
                    ? mockAttribute.concat(children)
                    : children;

                return {
                    headerName: TranslationHelper.translate(label, translate),
                    headerClass: 'group-parent-class',
                    openByDefault: !collapsed,
                    children: getColumnDefs(
                        childrenAttributes,
                        customData,
                        userSettings,
                        sourceSet,
                        enterprise,
                        getCheckbox(),
                        dragAndDropOptions,
                        hide,
                        actionHandlers,
                        showAlways
                    ),
                };
            }
            const headerName = `${TranslationHelper.translate(
                label,
                translate
            )}${
                formatting && formatting.suffix
                    ? TranslationHelper.translate(
                          formatting.suffix,
                          formatting.translate
                      )
                    : ''
            }`;
            const valueGetter = (params: ValueGetterParams) =>
                gridValueGetter({
                    value: params.data && params.data[params.column.getColId()],
                    sourceSet,
                    translate,
                    formatting,
                    type,
                    customData: customData && customData.roles,
                    userSettings,
                    actionHandlers,
                });
            const cellRenderer = cellRenderers[type];
            const filterValueGetter = (params: any) =>
                gridFilterValueGetters({ params, type });
            const filter = filters[type] || true;
            const comparator = gridComparators({
                dateFormat: userSettings.dateFormat,
                type,
            });
            const valueFormatter = (params: ValueFormatterParams) =>
                gridValueFormatter({
                    value: params.value,
                    formatting,
                    type,
                });
            const sort =
                sourceSet._meta.sorting &&
                sourceSet._meta.sorting.orderBy === id
                    ? sourceSet._meta.sorting.sortOrder
                    : undefined;
            const enableRowGroup = !type.toLowerCase().includes('icon');

            const enterpriseSettings = enterprise
                ? {
                      enableValue: true,
                      enableRowGroup,
                      enablePivot: true,
                  }
                : {};
            return {
                columnGroupShow: showAlways
                    ? columnGroupShow || undefined
                    : 'open',
                headerName,
                headerClass,
                field: id,
                colId: id,
                valueGetter,
                cellRenderer,
                cellRendererParams: {
                    sparklineOptions,
                },
                rowDrag: index === checkBox?.index ? rowDrag : undefined,
                rowDragText,
                cellClass,
                filterValueGetter,
                valueFormatter,
                filter,
                comparator,
                sort,
                minWidth: 100,
                suppressMenu: type === 'actions',
                checkboxSelection: index === checkBox?.index,
                headerCheckboxSelection: index === checkBoxHeader?.index,
                hide: index === hide?.index,
                suppressColumnsToolPanel:
                    suppressColumnsToolPanel || index === hide?.index,
                headerCheckboxSelectionFilteredOnly: true,
                menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],
                rowGroup,
                ...enterpriseSettings,
            };
        }
    );
};
